import { pick } from 'lodash'
import stelace from 'hc-core/composables/stelace'
import { remapToPaginated } from 'hc-core/composables/misc'

export async function list (context, params) {
  return remapToPaginated(
    await stelace.documents.list(
      pick(params, [
        'orderBy',
        'order',
        'page',
        'createdDate',
        'nbResultsPerPage',
        'id',
        'type',
        'label',
        'authorId',
        'targetId',
        'data',
        'metadata',
      ])
    )
  )
}

export async function create (context, attrs) {
  return await stelace.documents.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.documents.read(id)
}

export async function update (context, { id, attrs }) {
  return await stelace.documents.update(id, attrs)
}

export async function remove (context, { id }) {
  return await stelace.documents.remove(id)
}
