import stelace from 'hc-core/composables/stelace'

// ##### Generic calls #####
export async function read (context, { id }) {
  return await stelace.users.read(id)
}

export async function update (context, { id, attrs }) {
  return await stelace.users.update(id, attrs)
}
