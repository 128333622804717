import stelace, { fetchAllResults } from 'hc-core/composables/stelace'
import { isDefined } from 'hc-core/composables/misc'

// ##### Generic calls #####

export async function list ({ commit }, { page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', id = undefined, collection = undefined, locale = 'fr', metadata = undefined, getResults = true }) {
  const arr = await stelace.forward.get('/entries/advanced', {
    page,
    nbResultsPerPage,
    orderBy,
    order,
    id,
    locale,
    metadata,
    collection,
  })
  commit('content/setEntries', { key: collection, arr }, { root: true })
  return getResults ? arr.results : arr
}

// Fetch entry by name and set it store, allow preFetch use
export async function getEntryByName ({ commit }, { name }) {
  const entry = isDefined(name) ? await stelace.forward.get(`/entries/name/${name}`) ?? null : null
  commit('content/setCurrentEntry', { entry }, { root: true })
  return entry
}

// ##### Specific calls #####

export async function fetchBlogContent ({ state, commit }, { locale, nbResultsPerPage = 6, page = 1, categories }) {
  locale = locale || state.defaultLocale
  const metadata = { active: true }
  if (categories && categories !== 'all') metadata.categories = categories
  const blogList = await stelace.forward.get('/entries/advanced', { collection: 'blog', locale, nbResultsPerPage, page, metadata, customOrderConfig: { orderBy: 'publishedDate', order: 'desc', nulls: 'last' } })
  commit('content/setBlogList', blogList.results, { root: true })
  commit('content/setBlogPagination', {
    nbPages: blogList.nbPages,
    nbResultsPerPage: blogList.nbResultsPerPage,
    page: blogList.page
  }, { root: true })
  commit('content/setBlogCategory', categories, { root: true })
  return blogList.results
}

export async function fetchArticle ({ state, commit }, { locale, slug, relatedArticle = false }) {
  locale = locale || state.defaultLocale
  const articles = await stelace.entries.list({ collection: 'blog,blog-vdc', locale, name: slug })
  if (!relatedArticle) commit('setArticle', { article: articles[0] })
  else commit('content/setRelatedArticle', { article: articles[0] }, { root: true })
  return articles[0]
}

export async function fetchi18nContent ({ commit }, { locale }) {
  commit('content/setLocale', { locale }, { root: true })
  await import(/* webpackChunkName: 'i18n-stl-[request]' */`../../../../../lib/i18n/build/${locale}.json`).then(translations => {
    commit('content/seti18nContent', { content: translations.default }, { root: true })
  })
}

export async function fetchSubscriptionsContent ({ state, commit }, { locale = 'fr' } = {}) {
  locale = locale || state.defaultLocale
  const entriesRequest = (...args) => stelace.entries.list(...args)
  const subscriptions = await fetchAllResults(entriesRequest, { collection: 'subscription', locale })
  const features = await fetchAllResults(entriesRequest, { collection: 'feature', locale })
  commit('content/setSubscriptionsList', {
    subscriptions: subscriptions,
    features: features
  }, { root: true })
  return subscriptions
}

export async function fetchPage ({ state }, { locale, slug }) {
  locale = locale || state.defaultLocale
  const pages = await stelace.entries.list({ collection: 'page', locale, name: slug })
  return pages[0]
}

export async function fetchPages ({ state, commit }, { locale } = {}) {
  locale = locale || state.defaultLocale
  const pages = await stelace.forward.get('/entries/advanced', { collection: 'page', locale, nbResultsPerPage: 50, metadata: { active: true } })
  commit('content/setPages', { pages: pages.results }, { root: true })
  return pages.results
}

export async function fetchSeo ({ state, commit }, { locale, name, commitSeo = true }) {
  locale = locale || state.defaultLocale
  if (!name) return null
  const seos = await stelace.entries.list({ collection: 'seo', locale, name })
  if (seos[0] && seos[0].fields && commitSeo) await commit('content/setMetadata', seos[0].fields, { root: true })
  return seos[0]
}

export async function fetchEntries (context, { id = null, collection = undefined, locale = 'fr' }) {
  return stelace.entries.list({ id, collection, locale })
}

// Filtering from documents of type data
export async function getDataLabelOptions (context, { label = undefined, query = undefined, key = undefined }) {
  if (!label) return []
  return await stelace.forward.get('/data/options', { query, label, key })
}
