import { keyBy } from 'lodash'

export function setCurrentNaturalUser (state, { user }) {
  state.naturalUser = user
}

export function setCurrentUser (state, { user }) {
  state.user = user
}

export function setOrganizations (state, { organizations }) {
  state.organizations = organizations
  state.organizationsById = keyBy(organizations, 'id')
}

export function setUserOrganizations (state, { userOrganizations }) {
  state.userOrganizations = userOrganizations || {}
}

export function setUserCompany (state, { company }) {
  state.userCompany = company
}
