import { get } from 'lodash'

export function offerTypeId (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.custom.assetTypeIds.offer')
}

export function defaultOfferCategoryId (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.stelace.instant.searchOptions.modes.offer.defaultCategoryId')
}

export function offerCategoriesIds (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.stelace.instant.searchOptions.modes.offer.categoryIds')
}

export function companyTypeId (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.custom.assetTypeIds.company')
}

export function brandTypeId (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.custom.assetTypeIds.brand')
}

export function resumeTypeId (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.stelace.instant.searchOptions.modes.resume.assetTypesId')
}

export function searchFilters (state, getters, rootState, rootGetters) {
  return get(rootState, 'common.config.common.search.filters')
}

export function categoriesById (state) {
  return state.categoriesById
}
