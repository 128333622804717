export default function () {
  return {
    company: null,
    companyOffers: [],
    companyEntries: [],
    implantations: [],
    isGroup: false,
    activeLocations: [],
    applicantProfile: null,
  }
}
