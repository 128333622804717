import stelace from 'hc-core/composables/stelace'

export async function fetchTestimonials ({ commit }, { assetId }) {
  const testimonials = await stelace.ratings.list({ assetId, nbResultsPerPage: 100, label: 'testimonial', score: 100, metadata: { active: true } })
  commit('rating/setTestimonials', { testimonials }, { root: true })
  return testimonials
}

export async function fetchReviews (context, { assetId }) {
  return await stelace.ratings.list({ assetId, label: 'review' })
}
