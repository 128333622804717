export default function () {
  return {
    userCompany: null,
    user: {},
    naturalUser: {},
    organizations: [],
    userOrganizations: {
      brand: null,
      implantations: [],
      companies: [],
      users: [],
      locations: []
    }
  }
}
