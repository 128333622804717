export function setCompany (state, { company }) {
  state.company = company
}

export function setIsGroup (state, { isGroup }) {
  state.isGroup = isGroup
}

export function setCompanyOffers (state, { offers }) {
  state.companyOffers = offers
}

export function setCompanyEntries (state, { entries }) {
  state.companyEntries = entries || []
}

export function setImplantations (state, { implantations }) {
  state.implantations = implantations
}

export function setActiveLocations (state, { activeLocations }) {
  state.activeLocations = activeLocations
}

export function setApplicantProfile (state, { applicantProfile }) {
  state.applicantProfile = applicantProfile
}
