import { Cookies } from 'quasar'
import stelace from 'hc-core/composables/stelace.js'
import { get, intersection, set } from 'lodash'

// Use this synchronous function to know if the current user is authenticated
// without a network request
// e.g. to display login modal for unauthenticated users when they arrive in the app
export function getCurrentUserId () {
  const { userId } = stelace.auth.info()
  return userId
}

export function shouldAuthenticateAsOrg (user, config) {
  const isUpToDate = new Date(user.createdDate) > new Date('2021-07-01') || (get(user, 'platformData._private.version', 2) >= 3)
  // this.$_.get(config, 'stelace.instant.shouldAuthenticateAsOrg') || []
  return !!intersection(user.roles, ['client', 'member', 'editor', 'administrator']).length && isUpToDate
}

// OrganizationId related
export function getOrganizationIdFromCookie () {
  return get(Cookies.get('happycab-session'), 'stelaceOrganizationId', undefined)
}

export const createCookieInstance = ({ ssrContext } = {}) => {
  // Const declarations
  const hcCookiesName = 'happycab-session'
  const cookiesWrapper = (ssrContext && process.env.SERVER) ? Cookies.parseSSR(ssrContext) : Cookies

  // Functions as properties
  const getHappyCookie = (key = null) => {
    try {
      return key ? get(cookiesWrapper.get(hcCookiesName), key, undefined) : cookiesWrapper.get(hcCookiesName)
    } catch (e) { }
  }

  function bakeCookie ({ value = undefined, key = null }) {
    if (key) return set(Object.assign({}, getHappyCookie()), key, value)
    else return Object.assign({}, getHappyCookie(), value)
  }

  // Handle both set and remove actions
  function handleHappyCookie ({ action = 'set', value = null }) {
    try {
      const options = { expires: action === 'remove' ? -1 : 1, path: '/', sameSite: 'Lax', domain: process.env.HC_BASE_URL.includes('localhost') ? 'localhost' : 'happycab.fr' }
      if (action === 'set' && value) cookiesWrapper.set(hcCookiesName, bakeCookie({ value }), options)
      if (action === 'remove') cookiesWrapper.remove(hcCookiesName, options)
    } catch (e) { }
  }

  function removeOrganizationIdInCookie () {
    return handleHappyCookie({ value: bakeCookie({ key: 'stelaceOrganizationId' }) })
  }

  function logout () {
    removeOrganizationIdInCookie()
    handleHappyCookie({ action: 'remove' })
  }

  return {
    getHappyCookie,
    handleHappyCookie,
    getOrganizationIdFromCookie () { return getHappyCookie('stelaceOrganizationId') },
    setOrganizationIdInCookie (organizationId) {
      return handleHappyCookie({ value: bakeCookie({ key: 'stelaceOrganizationId', value: organizationId }) })
    },
    removeOrganizationIdInCookie,
    logout,
  }
}
