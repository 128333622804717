export function setCompaniesCount (state, { companiesCount }) {
  state.companiesCount = companiesCount
}

export function setOffersCount (state, { offersCount }) {
  state.offersCount = offersCount
}

export function setFrontpageData (state, { frontpageData }) {
  state.frontpageData = frontpageData
}
