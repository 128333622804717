import { get, intersection } from 'lodash'

export function currentUser (state) {
  if (state.user && state.user.id) return state.user
  else if (state.naturalUser && state.naturalUser.id) return state.naturalUser
  else return null
}

export function currentNaturalUser (state) {
  return state.naturalUser
}

export function userOrganizations (state) {
  return state.userOrganizations
}

export function userCompany (state) {
  return state.userCompany
}

// General roles
function hasRole (user, role) {
  return user && role && user.roles && user.roles.includes(role)
}

export function isApplicant (state, getters, rootState, rootGetters) {
  return hasRole(rootGetters['auth/currentNaturalUser'], 'applicant')
}

export function isOrganization (state, getters, rootState, rootGetters) {
  return hasRole(rootGetters['auth/currentUser'], 'organization')
}

// Extended remap of userOrganizations getter
export function uData (state) {
  return (key, checkLoading = undefined) => {
    const data = state.userOrganizations
    const def = {
      brand: null,
      users: [],
      offers: [],
      ratings: [],
      messages: [],
      companies: [],
      templates: [],
      applicants: [],
      applications: [],
      implantations: [],
      organizations: [],
      loading: [],
    }
    const dLoading = get(data, 'loading', [])
    // Specifically checking is specified key is loading
    if (checkLoading !== undefined) {
      // If at least one is loading, return true
      if (Array.isArray(key)) return intersection(dLoading, key).length > 0
      else return dLoading.includes(key) ?? false
    } else if (dLoading.includes(key)) return get(def, key, null) // Null value will be used as loading indicator / return null
    else return get(data, key, get(def, key, null))
  }
}

// uData, but better :)
export function uElements (state) {
  return (key, checkLoading = undefined) => {
    const data = state.userOrganizations
    // List of fields available in stelace/plugins/adv/hc_user/usersElements
    const def = {
      stats: [],
      organizations: [],
      implantations: [],
      companies: [],
      offers: [],
      templates: [],
      questionnaires: [],
      ratings: [],
      brand: null,
      messages: [],
      applications: [],
      applicants: [],
      users: [],
      profileAsset: null,
      // lastLogin: null, // Not used in app for now
      loading: [],
    }
    // Specifically checking is specified key is loading
    if (checkLoading !== undefined) {
      // If at least one is loading, return true
      if (Array.isArray(key)) return intersection(get(data, 'loading', []), key).length > 0
      else return get(data, 'loading', []).includes(key) ?? false
    } else if (get(data, 'loading', []).includes(key)) return get(def, key, null) // Null value will be used as loading indicator / return null
    else return get(data, key, get(def, key, null))
  }
}
