export function companiesCount (state) {
  return state.companiesCount
}

export function offersCount (state) {
  return state.offersCount
}

export function frontpageData (state) {
  return state.frontpageData || {}
}
