import stelace from 'hc-core/composables/stelace'

// ##### Generic calls #####

export async function create (context, { attrs }) {
  return await stelace.transactions.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.transactions.read(id)
}

export async function list (context, { params }) {
  return await stelace.transactions.list(params)
}

export async function update (context, { id, attrs }) {
  return await stelace.transactions.update(id, attrs)
}

export async function remove (context, { id }) {
  return await stelace.transactions.remove(id)
}

// Temporaray during applyDialog refund
export async function getUser (context, { id }) {
  return await stelace.users.read(id)
}
