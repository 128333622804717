import stelace from 'hc-core/composables/stelace'
import { get, set, compact, uniq } from 'lodash'

export async function search ({ rootGetters }, {
  filters,
  query = undefined,
  categoryId = undefined,
  location = undefined,
  maxDistance = undefined,
  assetTypeId = ['typ_kvY7dVE1XR01kuTwiXR0'],
  nbResultsPerPage = 12,
  page = 1,
  similarTo = undefined,
  without = undefined,
  sort = { _createdDate: 'desc' },
  createdBefore = undefined,
  createdAfter = undefined
}) {
  const searchObject = {
    query,
    populateAssets: true,
    active: true,
    validated: true,
    location,
    maxDistance,
    page,
    similarTo: Array.isArray(similarTo) && similarTo.length ? compact(uniq(similarTo)) : undefined,
    without: Array.isArray(without) && without.length ? compact(uniq(without)) : undefined,
    nbResultsPerPage,
    assetTypeId,
    categoryId,
    createdBefore,
    createdAfter,
  }
  if (filters) {
    if (assetTypeId.includes(rootGetters['common/offerTypeId'])) {
      // Tags
      ['advantages', 'formation', 'experience'].forEach((customAttribute, i) => {
        if (get(filters, customAttribute, false) && filters[customAttribute].length > 0 && filters[customAttribute] !== ['all']) set(searchObject, `customAttributes.${customAttribute}`, filters[customAttribute])
      })
      // Selects and numbers - can't do this same way as tags, strange errors reported by eslint
      if (get(filters, 'salaryMin.gte', false)) set(searchObject, 'customAttributes.salaryMin', filters.salaryMin)
      if (get(filters, 'salaryMax.lte', false)) set(searchObject, 'customAttributes.salaryMax', filters.salaryMax)
      if (get(filters, 'salaryPeriod', false) && (get(filters, 'salaryMin.gte', false) || get(filters, 'salaryMax.lte', false))) set(searchObject, 'customAttributes.salaryPeriod', filters.salaryPeriod)
      if (get(filters, 'remoteWork', false)) set(searchObject, 'customAttributes.remoteWork', filters.remoteWork)
      if (get(filters, 'jobSchedule', false)) set(searchObject, 'customAttributes.jobSchedule', filters.jobSchedule)
    }

    if (assetTypeId.includes(rootGetters['common/companyTypeId'])) {
      // Tags
      ['categories'].forEach((customAttribute, i) => {
        if (get(filters, customAttribute, false) && filters[customAttribute].length > 0 && filters[customAttribute] !== ['all']) set(searchObject, `customAttributes.${customAttribute}`, filters[customAttribute])
      })
      if (get(filters, 'category', false) && filters.category && filters.category !== ['all']) set(searchObject, 'customAttributes.categories', [filters.category])
      if (get(filters, 'ignoreGroups', false)) set(searchObject, 'filter', 'hasGroup != true')
    }
  }
  if (!query) set(searchObject, 'sort', sort)

  const res = await stelace.search.list(searchObject)
  return {
    results: res,
    paginationMeta: res.paginationMeta
  }
}

export async function companyBySlug ({ rootGetters, dispatch }, { slug }) {
  try {
    if (!slug) return null
    if (!rootGetters['common/companyTypeId']) await dispatch('common/fetchConfig', { forceRefresh: true }, { root: true })
    const company = await stelace.forward.get(`/assets/company/slug/${slug}`, {
      fields: ['offers', 'implantations']
    })
    return company
  } catch (error) {
    return null
  }
}

export async function fetchFrontpageData ({ commit }) {
  const frontpageData = await stelace.forward.get('/data/frontpage')
  commit('search/setFrontpageData', { frontpageData }, { root: true })
  return frontpageData
}

// Count companies
export async function countCompanies ({ commit, rootGetters }, { validated = true, active = true } = {}) {
  const companies = await stelace.assets.list({
    assetTypeId: rootGetters['common/companyTypeId'],
    nbResultsPerPage: 1,
    validated: validated,
    active: active
  })
  commit('search/setCompaniesCount', { companiesCount: companies.paginationMeta.nbResults }, { root: true })
  return companies
}

// Count offers
export async function countOffers ({ commit, rootGetters }, { validated = true, active = true } = {}) {
  const offers = await stelace.assets.list({
    assetTypeId: rootGetters['common/offerTypeId'],
    categoryId: rootGetters['common/offerCategoriesIds'],
    nbResultsPerPage: 1,
    validated: validated,
    active: active
  })
  commit('search/setOffersCount', { offersCount: offers.paginationMeta.nbResults }, { root: true })
  return offers
}

// Trigger Affinda parsing process
export async function affindaParseProcess (context, { assetId = null, userId = null, payload = {} }) {
  return await stelace.forward.post('/integrations/affinda/parseprocess', { assetId, userId, payload })
}
