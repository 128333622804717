// Mixin specific for this project
// For always loaded mixins, refer to mixins/global

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['common', 'content']),
    currentUser () { return this.$store.getters['auth/currentUser'] },
    isApplicant () { return this.$store.getters['auth/isApplicant'] },
    isOrganization () { return this.$store.getters['auth/isOrganization'] },
    userOrganizations () { return this.$store.getters['auth/userOrganizations'] },
    currentNaturalUser () { return this.$store.getters['auth/currentNaturalUser'] },
    registerURL () { return process.env.HC_APP_URL + '/inscription' },
  }
}
